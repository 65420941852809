@import "../base";

[v-cloak] {
     display: none;
}


.section-interesses {
	font-family: 'metropolismedium';
	table {
		.row-curso {
			p {
				&.h4 {
					margin-top: 0;
				}
			}
		}
	}
	h3 {

		margin-bottom: 30px;
	}
	.title-section {
		border-bottom: 1px solid $azulcpt;
		padding-bottom: 5px;
		&.title-depositos {
			margin-bottom: 20px;
		}
	}
	.opcoes_interesses {
		// .nav-tabs {
		// 	li {
		// 		a {
		// 			border-color: #ddd;
		// 			margin-right: 0;
		// 		}
		// 		&.active {
		// 			a {
		// 				background: $azulcpt;
		// 				border-bottom-color: $azulcpt;
		// 				color: #fff;
		// 			}
		// 		}
		// 	}
		// }
		.tab-content {
			@extend .clearfix;
			border-top: none;
			border: 1px solid #ddd;
			border-radius: 0px 0px 4px 4px;
			margin-bottom: 20px;
			.tab-pane {
				@extend .clearfix;
				padding-bottom: 20px;
				padding-top: 10px;
			}
			.opcoes {
				h4 {
					border-bottom: 1px solid #ddd;
					padding-bottom: 5px;
				}
				p {
					margin: 0 0 0 0;
					&.valor_sinal {
						color: $verdecpt;
						margin-bottom: 10px;
					}
					label {
						cursor: pointer;
						display:block;
						font-weight: normal;
						padding: 5px;
						&:hover {
							background: #eee;
						}
					}
				}
			}
		}
	}

	.etapa-matricula {
		@extend .clearfix;
		margin-bottom: 50px;
		margin-top: 30px;
		.subtitle-section {
			border-left: 4px solid $verdecpt;
			margin-bottom: 40px;
			padding-left: 10px;
		}

		.opcao-matricula {
			border: 1px solid $azulcpt;
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				background: #e3e3e3;
			}
			&.active {
				background: $azulcpt;
				color: #fff;
				.fa {
					color: #fff !important;
				}
			}
			&.opcao-hospedagem {
				padding-top: 10px;
				.icon-hospedagem {
					margin-bottom: 10px;
					.fa {
						color: $verdecpt;
						margin-left: 2px;
						margin-right: 2px;
					}
				}
			}
			&.opcao-parcela {
				margin-bottom: 10px;
				padding: 10px;
			}
		}

		.alert-sinal {
			margin-top: 15px;
		}

		&.etapa-resumo {
			h5 {
				font-weight: bold;
			}
		}


	}

	// .btn-hospedagem {
	// 	color: #fff;
	// 	&.com-hospedagem {
	// 		background-color: lighten($azulcpt, 15%);
	// 	}
	// 	&.sem-hospedagem {
	// 		background-color: lighten($azulcpt, 25%);
	// 	}
	// 	.fa {
	// 		color: $amarelocpt;
	// 	}
	// }





	.dados-depositos {
		.media {
			p {
				margin-bottom: 0;
			}
		}
	}
}